.header {
    z-index: 10;
    background: #333333;
    // color: #181812;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-width: 320px;

    > .container-fluid {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        background-color: #181822;
    }

    .logo-wrap {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .btn-tools-wrap {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        padding: 10px;
        color: white;

        > .btn,
        .dropdown-toggle {
            display: inline-block;
            color: #09b3af;
            padding: 5px;
            border: none;
            min-width: 30px;
            min-height: 30px;
            text-align: center;
            font-size: 20px;
            // color: $dark;
            transition: all 0.3s ease;

            &:hover {
                color: $primary;
            }

            &:after {
                display: none;
            }
        }
    }

    .user-area {
        margin: 0 0 0 auto;
        padding: 0 15px 0 0;
    }
}

.user-drop {
    position: relative;

    .dropdown-toggle {
        display: flex;
        align-items: center;
        color: $dark;
        // padding: 0;
        margin-top: 5px;
        padding-bottom:8px;
        border-radius: 10px;
        // border: 1px solid #181812;

        .avatar {
            width: 40px;
            height: 40px;
            text-align: center;
            color: $primary;
            background: rgba($primary, 0.35);
            border-radius: 100%;
            font-size: 20px;
            line-height: 40px;
            overflow: hidden;
            flex-shrink: 0;

            img {
                display: block;
                width: 100%;
                border-radius: 100%;
                min-height: 100%;
                object-fit: cover;
            }
        }

        .name {
            display: none;
            @include media-breakpoint-up(md) {
                flex-grow: 1;
                display: block;
                padding: 5px 0 0 10px;
                color: white;
                font-weight: bold;
                font-size: 15px;
            }
        }
    }

    .dropdown-menu {
        background: $dark;
        color: $white;
        font-size: 14px;

        .dropdown-item {
            padding: 0;
            &:hover,
            &:focus,
            &:active {
                background: #1d1d1d;
                color: $primary;

                a {
                    background: #1d1d1d;
                }
            }
        }

        a {
            display: block;
            padding: 0.375rem 0.625rem;
            color: $white;

            &:hover {
                background: #1d1d1d;
                //color: $primary;
            }
        }
    }
}

.search-drop {
    position: static;
    .menu-collasped & {
        .dropdown-menu {
            @include media-breakpoint-up(md) {
                padding-left: 80px !important;
            }
        }
    }

    .input-group-append .btn {
        padding-left: 12px;
        padding-right: 12px;
    }

    .dropdown-menu {
        top: 100% !important;
        left: 0 !important;
        right: 0 !important;
        transform: none !important;
        background: none !important;
        padding: 0 !important;
        border: none;
        border-radius: 0;
        //margin-top: 15px;
        min-width: 200px;
        transition: all 0.4s ease;
        z-index: 1;
        @include media-breakpoint-up(md) {
            padding-left: 230px !important;
        }
        &:before {
            z-index: -1;
            content: "";
            background: rgba($dark, 0.65);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: -99999px;
        }

        .search-form {
            padding: 15px;
            background: $white;
            border-top: 1px solid #e9ecef;
        }
    }
}

/* Logo Styles */
.logo {
    position: relative;
    width: 160px;
    padding: 15px;
    overflow: hidden;
    background: $dark;
    transition: all 0.3s ease;
    @include media-breakpoint-up(md) {
        width: 230px;
    }

    .menu-collasped & {
        @include media-breakpoint-up(md) {
            width: 80px;
        }

        img {
            &.large {
                @include media-breakpoint-up(md) {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &.small {
                @include media-breakpoint-up(md) {
                    margin: 0 0 0 6px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    a,
    span {
        display: inline-block;
        vertical-align: top;
        position: relative;
    }

    img {
        flex-shrink: 0;
        display: block;
        height: auto;
        max-width: 50px;
        transition: all 0.3s ease;

        &.small {
            max-width: 50px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
        }
    }
}
