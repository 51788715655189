/* Sidebar Styles */
.sidebar {
    position: fixed;
    top: 79px;
    left: 0;
    bottom: 0;
    background: $dark;
    width: 230px;
    z-index: 9;
    overflow: hidden;
    overflow-y: auto;
    transition: all 0.3s ease;
    transform: translateX(-100%);
    @include media-breakpoint-up(md) {
        transform: none;
    }

    .menu-collasped & {
        transform: translateX(0);
        @include media-breakpoint-up(md) {
            width: 80px;
            transform: none;
        }
    }
}
/* Main Nav Styles */
.main-navbar {
    padding: 10px 0;
}

.main-nav {
    display: block;
    width: 100%;
    .menu-collasped & {
        li {
            a {
                //transition-delay: 0.3s;
                @include media-breakpoint-up(md) {
                    justify-content: center;
                    &:hover {
                        .text {
                            opacity: 1;
                            visibility: visible;
                            left: 75px;
                        }
                    }
                }
            }
            @include media-breakpoint-up(md) {
                .text {
                    position: fixed;
                    left: 80px;
                    background: $primary;
                    border-radius: 2px;
                    padding: 3px 5px;
                    font-size: 14px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s ease;
                    transition-delay: 0;

                    &:before {
                        content: "";
                        transform: translateY(-50%);
                        position: absolute;
                        top: 50%;
                        right: 100%;
                        border-style: solid;
                        border-width: 4px 4px 4px 0;
                        border-color: transparent $primary transparent transparent;
                    }
                }
            }
        }
    }

    li {
        margin: 0 0 5px;
        a {
            padding: 12px 15px;
            display: flex;
            align-items: center;
            color: $white;
            border-left: 5px solid transparent;
            position: relative;
            min-height: 46px;

            &.active {
                background: $gray-900;
                border-left-color: $primary;
                .icon {
                    color: $primary;
                }
            }

            &:hover {
                .icon {
                    color: $primary;
                }
            }

            .icon {
                line-height: 1;
                margin: -2px 8px 0 0;
                font-size: 18px;
                transition: color 0.3s ease;
            }
        }
    }

    .dropdown {
        position: relative;
        .dropdown-toggle {
            &:after {
                position: absolute;
                right: 15px;
                top: 50%;
            }
        }
        &.show {
            > .dropdown-toggle {
                background: $gray-900;
                border-left-color: $primary;
                .icon {
                    color: $primary;
                }
            }
        }

        .dropdown-menu {
            position: relative;
            border: none;
            border-radius: 0;
            background: #383b42;
            min-width: 1px;
            width: 100%;
        }
    }
}
