// Google Font Import
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
$font-family: "Roboto", Arial, Helvetica, sans-serif;

// Variables and Bootstrap Customization

// Color system

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #f1f3f6;
$gray-300: #e3e3e3;
$gray-400: #dfdfdf;
$gray-500: #bebebe;
$gray-600: #f6f8fa;
$gray-700: #495057;
$gray-800: #2e3139;
$gray-900: #1d1d1d;
$black: #000;

$grays: ();
$grays: map-merge(
    (
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900,
    ),
    $grays
);

$blue: #09b3af;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff242e;
$orange: #fd7e14 !default;
$yellow: #ffc33c;
$green: #25ca58;
$teal: #20c997 !default;
$cyan: #17a2b8;

$colors: ();
$colors: map-merge(
    (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "gray": $gray-600,
        "gray-dark": $gray-800,
    ),
    $colors
);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $teal;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// Spacing

$spacer: 0.625rem; // 10px
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.6,
            // 6px
        ),
        2: (
            $spacer * 0.5,
            // 5px
        ),
        3: $spacer,
        // 10px
        4:
            (
                $spacer * 1.5,
                // 15px
            ),
        5: (
            $spacer * 0.5,
            // 5px
        ),
        10: (
            $spacer,
            // 10px
        ),
        15: (
            $spacer * 1.5,
            // 15px
        ),
        20: (
            $spacer * 2,
            // 20px
        ),
        25: (
            $spacer * 2.5,
            // 25px
        ),
        30: (
            $spacer * 3,
            // 30px
        ),
        35: (
            $spacer * 3.5,
            // 35px
        ),
        40: (
            $spacer * 4,
            // 40px
        ),
        45: (
            $spacer * 4.5,
            // 45px
        ),
        50: (
            $spacer * 5,
            // 50px
        ),
        55: (
            $spacer * 5.5,
            // 55px
        ),
        60: (
            $spacer * 6,
            // 60px
        ),
    ),
    $spacers
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-900;

// Links

$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: none;

// Paragraphs
$paragraph-margin-bottom: 1rem;

// Typography

// stylelint-disable value-keyword-case
$font-family-sans-serif: $font-family;
$font-family-base: $font-family-sans-serif;

$font-size-base: 0.875rem; // 14px // Assumes the browser default, typically `16px`
$line-height-base: 1.285; // 1.285 * 14 = 18px

$font-size-sm: 0.75rem; // 12px
$line-height-sm: 1.3333; // 1.33 * 12 = 16px;

$font-size-lg: 1.125rem; // 18px
$line-height-lg: 1.2222; // 1.22 * 18 = 22px

$font-weight-lighter: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-samibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;

$font-weight-base: $font-weight-normal;

$h1-font-size: $font-size-base * 1.8571; // 1.8571 * 14 = 26px
$h2-font-size: $font-size-base * 1.7142; // 24px
$h3-font-size: $font-size-base * 1.5714; // 22px
$h4-font-size: $font-size-base * 1.4285; // 20px
$h5-font-size: $font-size-base * 1.1428; // 16px
$h6-font-size: $font-size-base; // 14px

$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2857;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    sm: 100%,
    md: 768px,
    lg: 960px,
    xl: 1140px,
    xxl: 1370px,
);

// Options
//$enable-rounded: false;
$enable-shadows: false;
$border-width: 1px; // 1px
$enable-caret: true;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// height = 40px
$input-btn-padding-y: 0.625rem; // 10px
$input-btn-padding-x: 0.9375rem; // 15px
$input-btn-font-size: $font-size-base; // 14px
$input-btn-line-height: (18/14); // 18px

// height = 36px
$input-btn-padding-y-sm: 0.5rem; // 8px
$input-btn-padding-x-sm: $input-btn-padding-x; // 15px
$input-btn-font-size-sm: $input-btn-font-size; // 14px
$input-btn-line-height-sm: $input-btn-line-height; // 18px

// height = 44px
$input-btn-padding-y-lg: 0.75rem; // 12px
$input-btn-padding-x-lg: $input-btn-padding-x; // 15px
$input-btn-font-size-lg: $input-btn-font-size; // 14px
$input-btn-line-height-lg: $input-btn-line-height; // 18px

$input-btn-focus-width: 0;
$input-btn-focus-color: transparent;
$input-btn-focus-box-shadow: none;
$input-btn-border-width: $border-width; // 1px

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: none;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: none;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 2px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

// Forms

$input-font-weight: $btn-font-weight;
$input-color: $gray-900;
$input-border-color: $gray-400;
$input-border-width: $input-btn-border-width;
$input-box-shadow: none;

$input-border-radius: $btn-border-radius;
$input-border-radius-lg: $btn-border-radius;
$input-border-radius-sm: $btn-border-radius;

$input-focus-border-color: $primary;
$input-focus-color: $input-color;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-600;
$input-plaintext-color: $gray-500;

/* Range Slider Variables */
$height: 35px;
$thumb-height: 35px;
$track-height: 15px;
$upper-color: #1a1a1a;
$lower-color: #808080;
$thumb-color: $primary;
$thumb-hover-color: $primary;
$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

// Customize Bootstrap Variables above this line
@import "~bootstrap/scss/bootstrap";
