.spinner-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba($black, 0.75);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
