#root {
    position: relative;
    overflow: hidden;
    background-color:#333333;
    color:#ffffff;
    padding-bottom:initial;
    // height: 90vh;
}
.wrapper {
    position: relative;
//    background:blueviolet;
    // width: 100%;
    // height: 100vh;  
}
.main {
    position: relative;
    margin-top: 79px;
    @include media-breakpoint-up(md) {
        margin-left: 80px;
    }
    @include media-breakpoint-up(lg) {
        margin-left: 230px;
        transition: all 0.3s ease;
    }

    .full-page &,
    .login & {
        transition: none;
        padding: 0;
        margin: 0;
    }

    .menu-collasped & {
        @include media-breakpoint-up(lg) {
            margin-left: 80px;
        }
    }
}
.ErrorColor {
    color: red;
}

.toppings-list {
    list-style: none;
    padding: 0;
    li {
        margin-bottom: 0.5rem;
    }

    .toppings-list-item {
        display: flex;
        justify-content: space-between;
        & label {
            margin-left: 10px;
        }
    }
}

.thumbnail {
    border: 1px solid #ddd; /* Gray border */
    border-radius: 4px; /* Rounded border */
    padding: 5px; /* Some padding */
    width: 150px; /* Set a small width */
}

/* Add a hover effect (blue shadow) */
.thumbnail:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.thumbnail-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
        // background-color: black;
}

.thumbnail-list li {
    list-style-type: none;
    flex: 25%;
    max-width: 25%;
    // background: black;
    //padding: 0 4px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .thumbnail-list li {
        list-style-type: none;
        flex: 50%;
        max-width: 50%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .thumbnail-list li {
        list-style-type: none;
        flex: 100%;
        max-width: 100%;

    }
}

.ic_rangeslider {
    font-size: 14px;
    line-height: 1.5;
    color: #808080;
    h4 {
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 30px;
    }
    .value {
        font-size: 26px;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
    }
    .slider {
        display: block;
        appearance: none;
        width: 100%;
        margin: 0 0 40px;
        background-color: transparent;
        height: $height;
        overflow: hidden;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: $height;
            background: $lower-background;
        }
        &::-webkit-slider-thumb {
            position: relative;
            appearance: none;
            height: $thumb-height;
            width: $thumb-height;
            background: $thumb-color;
            border-radius: 100%;
            border: 0;
            top: 50%;
            margin-top: (-$thumb-height/2);
            box-shadow: webkit-slider-thumb-shadow();
            transition: background-color 150ms;
        }
        &::-moz-range-track,
        &::-moz-range-progress {
            width: 100%;
            height: $height;
            background: $upper-background;
        }
        &::-moz-range-progress {
            background: $lower-background;
        }
        &::-moz-range-thumb {
            appearance: none;
            margin: 0;
            height: $thumb-height;
            width: $thumb-height;
            background: $thumb-color;
            border-radius: 100%;
            border: 0;
            transition: background-color 150ms;
        }
        &::-ms-track {
            width: 100%;
            height: $height;
            border: 0;
            // color needed to hide track marks
            color: transparent;
            background: transparent;
        }
        &::-ms-fill-lower {
            background: $lower-background;
        }
        &::-ms-thumb {
            appearance: none;
            height: $thumb-height;
            width: $thumb-height;
            background: $thumb-color;
            border-radius: 100%;
            border: 0;
            transition: background-color 150ms;
            // IE Edge thinks it can support -webkit prefixes
            top: 0;
            margin: 0;
            box-shadow: none;
        }
        &:hover,
        &:focus {
            &::-webkit-slider-thumb {
                background-color: $thumb-hover-color;
            }
            &::-moz-range-thumb {
                background-color: $thumb-hover-color;
            }
            &::-ms-thumb {
                background-color: $thumb-hover-color;
            }
        }
    }
    .button-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .btn {
            min-width: inherit;
            padding: 0;
            border: none !important;
            color: #808080;
            font-size: 50px;
            &:hover {
                color: #fff;
            }
            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
            }
        }
    }
    @media screen and (max-width: 767px) {
        h4 {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 560px) {
        font-size: 12px;
        line-height: 1.6;
        p {
            margin-bottom: 30px;
        }
    }
}
