main {
    overflow: hidden;
}
.slick-slider {
    position: relative;

    .slick-slide {
        .slide-image,
        .slider-image {
            position: relative;
            width: 100%;
            &[class^="embed-responsive-"],
            &[class*=" embed-responsive-"] {
                padding-top: 56.25%;
                background-size: cover;
                background-position: 50% 50%;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 0;
        line-height: 1;
        border: 0;
        box-shadow: 0;
        outline: none;
        padding: 0;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        background: none;
        z-index: 5;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: solid $white;
            transform: rotate(45deg);
            opacity: 0.8;
        }

        &.slick-prev {
            left: 25px;
            &:before {
                border-width: 0 0 7px 7px;
            }
        }

        &.slick-next {
            right: 25px;
            &:before {
                border-width: 7px 7px 0 0;
            }
        }
    }

    .slick-dots {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        margin: 0 -5px;
        list-style-type: none;
        padding: 0;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;

        > li {
            margin: 5px;
            cursor: pointer;

            &:hover,
            &.slick-active {
                button {
                    opacity: 1;
                }
            }
        }

        button {
            font-size: 0;
            line-height: 1;
            border: 0;
            box-shadow: 0;
            outline: none;
            padding: 0;
            display: block;
            width: 30px;
            height: 3px;
            background: #fff;
            opacity: 0.5;
            transition: opacity 0.3s ease;
            margin: 10px 0;
        }
    }

    &.ic_slider,
    .ic_slider & {
        .slide-image,
        .slider-image {
            &[class^="embed-responsive-"],
            &[class*=" embed-responsive-"] {
                padding-top: 41.25%;
                min-height: 180px;
            }
        }

        .slide-caption {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
        }
        h2 {
            font-size: 32px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 10px;
            text-shadow: 2px 3px 10px #000;

            @media screen and (min-width: 992px) {
                font-size: 55px;
                text-shadow: 4px 5px 20px #000;
            }
        }

        .btn {
            border-color: #ec008c;
            background: #ec008c;
            color: #fff;
        }
    }
}

.slide-list {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 -10px;
    list-style-type: none;
    padding: 0;

    > li {
        padding: 10px;
        width: 100%;
    }

    .slide-row {
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        display: flex;
        flex-flow: row wrap;
        border-radius: 4px;
    }

    .tag-line-one {
        display: block;
        font-weight: 700;
        font-size: 16px;
        margin: 0 0 8px;
    }

    .tag-line-two {
        display: block;
    }

    .img-holder {
        width: 180px;
        overflow: hidden;
        border-right: 1px solid rgba(0, 0, 0, 0.125);

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text-holder {
        padding: 0 10px;
        width: calc(100% - 360px);
    }

    .btns-holder {
        width: 180px;
        align-self: center;
        justify-content: center;
    }
}

.custom-popup {
    color: #000;

    .modal-title {
        color: #000;
    }

    .ic_info-list {
        color: #000;
        &.opening-hours {
            .info_item {
                flex: 0 0 55%;
            }
            .info_value {
                flex: 0 0 45%;
            }
        }

        .info_item {
            color: #000;
        }

        .info_value {
            color: #000;
        }
    }

    // .modal-content {
    //     color: #664d03;
    //     background-color: #fff3cd;
    //     border-color: #ffecb5;
    // }
}

.crop-container {
    position: relative;
    width: 100%;
    display: block;
    padding-top: 56.25%;
}

.cropimage-container{
    display: flex;  
    flex-direction: row;
}

.cropimage-tools{
    display:flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;      
    cursor: pointer;
}

.cropimage-icons{
    display: flex;
    flex-direction: column;
    font-size:1rem;   
    margin-bottom: 1rem;
}

.cropimage-text{
   font-size:0.5rem;   
}
