body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background: $gray-200;
    background:#333333 ;
    min-width: 320px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    outline: none;
    // text-decoration: none;
    color:white;
    transition: all 0.3s ease;

    &:hover {
        text-decoration: none;
    }
}

.text-sm {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
}

.text-lg {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
}

.text-base {
    font-size: $font-size-base;
    line-height: $line-height-base;
}

h1,
.h1 {
    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 2.1428; // 30px
    }
}
h2,
.h2 {
    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 1.8571; // 26px
    }
}
h3,
.h3 {
    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 1.7142; // 24px
    }
}
h4,
.h4 {
    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 1.5714; // 22px
    }
}
h5,
.h5 {
    @include media-breakpoint-up(md) {
        font-size: $font-size-base * 1.2857; // 18px
    }
}

b,
strong {
    font-weight: 700;
}

.bg-stretch {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

label {
    display: inline-block;
    vertical-align: top;
    font-weight: 400;
    font-size: $font-size-base;
}

.error-msg {
    display: none;
    vertical-align: top;
    font-weight: 400;
    color: $danger;
    font-size: $font-size-sm;

    .form-error & {
        display: inline-block;
    }
}

.form-control {
    // border-left-width: 5px;
    // border-left-color: $primary;
}

textarea.form-control {
    resize: vertical;
    min-height: 130px;
    height: 130px;
}

.form-group {
    margin: 0 0 20px;
    @include media-breakpoint-up(sm) {
        margin: 0 0 25px;
    }
}

.btn {
    letter-spacing: 0.5px;
    text-align: center;
    text-transform: capitalize;

    > svg {
        pointer-events: none;
    }

    &.btn-circle {
        border-radius: 100% !important;
        width: 44px;
        height: 44px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 16px;
        line-height: 22px;

        &.btn-sm {
            width: 36px;
            height: 36px;
            line-height: 18px;
        }

        &.btn-lg {
            width: 48px;
            height: 48px;
            font-size: 20px;
        }
    }
}

.card {
    margin-bottom: 25px;
    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
    }
}

.btns-holder {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -5px;

    &.justify-content-right,
    &.text-right {
        justify-content: flex-end;
    }

    > .btn,
    > a,
    > button {
        margin: 5px;
    }
}

.container {
    &.is-builder,
    &.is-container {
        @include media-breakpoint-up(xl) {
            max-width: 1024px;
        }
    }
}

.user-form {
    position: relative;

    .form-row {
        align-items: center;
    }

    .custom-control {
        .custom-control-label {
            line-height: inherit;
        }
    }
}
