.page-header {
    position: relative;

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3 {
        margin: 0 0 15px;
        &:only-child {
            margin: 0;
        }
        .icon {
            display: inline-block;
            vertical-align: top;
            margin: 0 8px 0 0;
            color: $primary;
            font-size: 24px;
            line-height: 36px;
            @include media-breakpoint-up(md) {
                font-size: 28px;
                line-height: 45px;
            }
        }

        .small {
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
